import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { UBE_URL } from 'utils/urls';
import { getHeaders } from 'utils/http';
import { toastActions } from '../ToastSlice';
import i18n from 'i18next';
import { Item } from '../AssetLiabilitySlice/types';

//TODO: Refactor the slice into multiple files and add the missing types.

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: UBE_URL }),
  tagTypes: ['Scenarios', 'Files'],
  endpoints: builder => ({
    addMultipleAsset: builder.mutation<any, any>({
      query: data => ({
        url: `/group/${data.groupId}/item/batch`,
        method: 'POST',
        body: data.payload,
        headers: getHeaders(),
      }),
    }),
    updateMultipleAsset: builder.mutation<any, Item[]>({
      query: data => ({
        url: `/group/item/batch`,
        method: 'POST',
        body: data,
        headers: getHeaders(),
      }),
      async onQueryStarted(itemBatch, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            toastActions.showSuccessToast({
              show: true,
              message: i18n.t('connectedAccounts.alerts.updatedAccounts'),
            }),
          );
        } catch (err) {
          dispatch(
            toastActions.showErrorToast({
              show: true,
              message: i18n.t('connectedAccounts.alerts.errorUpdatingAccounts'),
            }),
          );
        }
      },
    }),
    getScenarios: builder.query<any, any>({
      query: groupId => ({
        url: `/group/${groupId}/scenario`,
        method: 'GET',
        headers: getHeaders(),
      }),
      providesTags: ['Scenarios'],
    }),
    addScenario: builder.mutation<any, any>({
      query: data => ({
        url: `/group/${data?.payload?.groupId}/scenario`,
        method: 'POST',
        body: data.payload,
        headers: getHeaders(),
      }),
      invalidatesTags: ['Scenarios'],
    }),
    deleteScenario: builder.mutation<any, any>({
      query: data => ({
        url: `/group/${data.groupId}/scenario/${data.scenarioId}`,
        method: 'DELETE',
        headers: getHeaders(),
      }),
      invalidatesTags: ['Scenarios'],
    }),
  }),
});

export const {
  useUpdateMultipleAssetMutation,
  useAddMultipleAssetMutation,
  useAddScenarioMutation,
  useDeleteScenarioMutation,
  useGetScenariosQuery,
} = apiSlice;
