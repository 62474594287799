import { call, put, select, takeLatest, delay, all } from 'redux-saga/effects';
import { getInstance } from 'utils/http';
import { request } from 'utils/request';
import { UBE_URL } from 'utils/urls';
import { groupActions as actions } from '.';
import { selectUser } from '../UserSlice/selectors';
import {
  selectFormData,
  selectStakeholderData,
  selectStakeholdersGroupId,
  selectCurrentStakeholderId,
  selectCurrentGroupId,
  selectGroupsError,
  selectCurrentStakeholder,
  selectGroups,
  selectGroupsByUserId,
} from './selectors';
import {
  GroupCalculations,
  GroupData,
  SharedItem,
  StakeholdersGroupId,
} from './types';
import { toastActions } from '../ToastSlice';
import { defaultErrorMessage } from 'constants/error';
import { getItemAverages, groupItemsByType } from 'utils/assetValues';
import { extractCalcFromItems } from 'utils/calcItemsHelper';
import { getRenderItemValue } from '../AssetLiabilitySlice/saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { CurrencyStateProps } from 'providers/CurrencyProvider/types';
import { SupportedStatus } from '../AssetLiabilitySlice/types';

// function* toastSuccess(message) {
//   yield delay(500);
//   yield put(toastActions.showErrorToast(message));
// }

function* toastError() {
  yield delay(500);
  const error: any = yield select(selectGroupsError);
  const message = {
    show: true,
    message: error && error.message ? error.message : defaultErrorMessage,
  };
  yield put(toastActions.showErrorToast(message));
}

export function* loadGroupSaga() {
  const instance = getInstance(UBE_URL);
  try {
    const response = yield call(instance.get, `/group`);
    if (response === void 0 || response === null) {
      throw new Error('an error ocurred getting groups');
    }

    if (
      !response.data ||
      !response.data.payload ||
      !Array.isArray(response.data.payload)
    ) {
      throw new Error(defaultErrorMessage);
    }
    const currencyState = JSON.parse(
      localStorage.getItem('currencyState') || '{}',
    );
    //TODO get currency from saga instead of local storage
    const result = response.data.payload.map((group: GroupData) => {
      const renderItems = group.items.map(item => {
        return getRenderItemValue(item, currencyState);
      });
      return { ...group, items: renderItems };
    });

    yield put(actions.loadGroupDataSuccess(result));
  } catch (error: any) {
    yield put(actions.setGroups([]));
    yield put(actions.setCurrentGroupId());
  }
}

export function* createGroupSaga() {
  yield delay(500);
  const formData: any = yield select(selectFormData);
  if (formData === undefined) {
    yield put(actions.setServiceError({ message: 'Invalid data' }));
    return;
  }
  const instance = getInstance(UBE_URL);
  try {
    const response = yield call(instance.post, '/group', formData);
    if (!response || !response.data || !response.data.payload) {
      throw new Error('an error ocurred creating a group');
    }
    yield put(actions.createGroupSuccess());
    yield put(actions.cleanupGroupOperation());
    yield put(
      toastActions.showSuccessToast({
        show: false,
        message: 'Group created successfully',
      }),
    );
  } catch (error: any) {
    yield put(
      actions.setServiceError({
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
    yield put(
      toastActions.showErrorToast({
        show: true,
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* editGroupSaga() {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  };
  yield delay(500);

  try {
    const formData = yield select(selectFormData);

    if (!formData) {
      throw new Error('Invalid data provided');
    }
    if (!formData.groupId) {
      throw new Error('Invalid group id');
    }

    const response = yield call(
      request,
      `${UBE_URL}/group/${formData.groupId}`,
      {
        headers: headers,
        method: 'PATCH',
        body: JSON.stringify(formData),
      },
    );
    if (!response) {
      throw new Error('An error ocurred updating Group/Entity');
    }
    yield put(actions.setEditGroupSuccess());
    yield put(actions.cleanupGroupOperation());
  } catch (error: any) {
    yield put(
      actions.setServiceError({
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
    yield call(toastError);
  }
}

export function* deleteGroupSaga() {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  };
  yield delay(500);

  try {
    const formData = yield select(selectFormData);
    if (!formData) {
      throw new Error('Invalid data provided');
    }
    if (!formData.groupId) {
      throw new Error('Invalid group id');
    }
    const response = yield call(
      request,
      `${UBE_URL}/group/${formData.groupId}`,
      {
        headers: headers,
        method: 'DELETE',
      },
    );
    if (!response) {
      throw new Error('An error ocurred updating Group/Entity');
    }
    yield put(actions.deleteStakeholderSuccess());
    yield put(actions.cleanupGroupOperation());
  } catch (error: any) {
    yield put(
      actions.setServiceError({
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
    yield call(toastError);
  }
}

export function* getGroupTypesSaga() {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  };
  yield delay(500);
  try {
    const response = yield call(
      request,
      `${UBE_URL}/signup/static/group-types`,
      {
        method: 'GET',
        headers: headers,
      },
    );
    if (response === void 0 || response === null) {
      throw new Error('an error ocurred getting group types');
    }
    if (!response.payload) {
      throw new Error(defaultErrorMessage);
    }
    yield put(actions.getGroupTypesSuccess(response.payload));
  } catch (error: any) {
    yield put(
      actions.getGroupTypesError({
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* getGroupSaga() {
  const instance = getInstance(UBE_URL);
  try {
    const response = yield call(instance.get, `/group`);
    if (response === void 0 || response === null) {
      throw new Error('an error ocurred getting groups');
    }

    if (
      !response.data ||
      !response.data.payload ||
      !Array.isArray(response.data.payload)
    ) {
      throw new Error(defaultErrorMessage);
    }
    yield put(actions.setGroups(response.data.payload));
  } catch (error: any) {
    yield put(actions.setGroups([]));
    yield put(actions.setCurrentGroupId());
  }
}

export function* groupsDataRefreshEffectsSaga() {
  yield delay(500);
  const groups: GroupData[] = yield select(selectGroups);
  const filteredGroups: GroupData[] = yield select(selectGroupsByUserId);
  const currentGroupId = yield select(selectCurrentGroupId);

  try {
    let currencyState: CurrencyStateProps = JSON.parse(
      // @ts-ignore
      localStorage.getItem('currencyState'),
    );
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    };
    if (Object.keys(currencyState).length === 0) {
      const response = yield call(
        request,
        `${UBE_URL}/calculation/exchangeRates/USD`,
        {
          method: 'GET',
          headers: headers,
        },
      );

      if (!response) {
        throw new Error(`Unable to get rates for USD`);
      }

      // get data from api
      const changedState: CurrencyStateProps = {
        ...currencyState,
        isFetchingBaseCurrency: false,
        base: 'USD',
        rates: response.conversion_rates,
        timeLastFetched: Date.now(),
      };
      localStorage.setItem('currencyState', JSON.stringify(changedState));
      currencyState = changedState;
    }
    //TODO avoid recalculating everything everytime that the current group changes
    const groupsAssets = yield call(
      groupItemsByType,
      groups
        .flatMap(g => g.items)
        .filter(
          i =>
            i.itemType === 'GROUP_ASSET' && i.status === SupportedStatus.ACTIVE,
        ),
      true,
    );
    const selectedGroupAssets = yield call(
      groupItemsByType,
      groups
        .find(group => group._id === currentGroupId)
        ?.items.filter(
          i =>
            i.itemType === 'GROUP_ASSET' && i.status === SupportedStatus.ACTIVE,
        ) || [],
      true,
    );
    const currentGroup = yield call(() =>
      groups.find(group => group._id === currentGroupId),
    );

    const allGroupsTotals: GroupCalculations = yield call(
      extractCalcFromItems,
      {
        filteredGroups,
        currencyState,
      },
    );
    const currentGroupTotals: GroupCalculations = yield call(
      extractCalcFromItems,
      {
        filteredGroups: currentGroup ? [currentGroup] : [],
        currencyState,
      },
    );

    yield put(
      actions.setGroupCalculations({
        all: allGroupsTotals,
        current: currentGroupTotals,
      }),
    );

    yield put(
      actions.setGroupAverages({
        all: getItemAverages({
          data: groupsAssets,
          groups,
        }),
        current: selectedGroupAssets
          ? getItemAverages({
              data: selectedGroupAssets,
              groups: currentGroup ? [currentGroup] : [],
            })
          : [],
      }),
    );
    yield put(actions.reloadGroupCalculationsSuccess());
  } catch (error) {
    yield put(
      actions.setServiceError({
        message: 'An error ocurred getting group averages',
      }),
    );
    yield call(toastError);
  }
}

export function* addStakeholderSaga() {
  yield delay(500);
  const stakeholderData: any = yield select(selectStakeholderData);
  if (!stakeholderData) {
    yield put(actions.addStakeholderError({ message: 'Invalid data' }));
    return;
  }
  try {
    const { ...formData } = stakeholderData;
    const { entities } = formData;
    delete formData.entities;

    if (!entities) {
      throw new Error('an error ocurred adding stakeholder');
    }

    const instance = getInstance(UBE_URL);
    const response = yield all(
      entities.map(entityId =>
        call(
          instance.post,
          `/group/${entityId}/stakeholder`,
          JSON.stringify(formData),
        ),
      ),
    );

    if (!response) {
      throw new Error('an error ocurred adding stakeholder');
    }
    // Invite user to group by email
    // const invitationData = {
    //   taskName: 'INVITE_ACTOR_TO_GROUP',
    //   parentObject: groupId,
    //   taskType: 'INVITE_ACTOR_TO_GROUP',
    //   baseContext: {
    //     groupId: groupId,
    //     email: formData.email,
    //   },
    // };
    // yield call(request, `${UBE_URL}/task`, {
    //   method: 'POST',
    //   headers: headers,
    //   body: JSON.stringify(invitationData),
    // });
    yield put(
      toastActions.showSuccessToast({
        show: true,
        message: 'Added successfully',
      }),
    );
    yield put(actions.addStakeholderSuccess());
    yield put(actions.cleanupGroupOperation());
  } catch (error: any) {
    yield put(
      actions.addStakeholderError({
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
    toastActions.showErrorToast({
      show: true,
      message:
        error.message || error.message.length > 0
          ? error.message
          : defaultErrorMessage,
    });
  }
}

export function* editStakeholderSaga() {
  const stakeholderData: any = yield select(selectStakeholderData);
  const currentStakeholder = yield select(selectCurrentStakeholder);

  if (stakeholderData === undefined || currentStakeholder === undefined) {
    yield put(actions.editStakeholderError({ message: 'Invalid data' }));
    return;
  }

  try {
    const instance = getInstance(UBE_URL);
    const response = yield call(
      instance.put,
      `/group/${currentStakeholder.entity}/stakeholder/${currentStakeholder._id}`,
      JSON.stringify(stakeholderData),
    );

    if (!response) {
      throw new Error('an error ocurred updating stakeholder');
    }
    yield put(
      toastActions.showSuccessToast({
        show: true,
        message: 'Updated successfully',
      }),
    );
    yield put(actions.editStakeholderSuccess());
    yield put(actions.cleanupGroupOperation());
  } catch (error: any) {
    yield put(
      actions.editStakeholderError({
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
    toastActions.showErrorToast({
      show: true,
      message:
        error.message || error.message.length > 0
          ? error.message
          : defaultErrorMessage,
    });
  }
}

export function* updateStatusStakeholderSaga(
  action: PayloadAction<{ accepted: boolean; sharedItem?: SharedItem }>,
) {
  try {
    if (!action.payload.sharedItem) {
      throw new Error('Invalid data');
    }
    const instance = getInstance(UBE_URL);
    const response = yield all(
      action.payload.sharedItem.accessEntities.map(accessEntity =>
        call(
          instance.patch,
          `/group/${accessEntity.entityId}/stakeholder/${accessEntity.stakeholderId}/update/status?accepted=${action.payload.accepted}`,
        ),
      ),
    );

    if (!response) {
      throw new Error('an error ocurred updating stakeholder');
    }
    yield put(actions.updateStakeholderSuccess());
    yield put(actions.cleanupGroupOperation());
  } catch (error: any) {
    yield put(
      actions.updateStakeholderError({
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
    toastActions.showErrorToast({
      show: true,
      message:
        error.message || error.message.length > 0
          ? error.message
          : defaultErrorMessage,
    });
  }
}

export function* updateStakeholderSaga() {
  const sharedItemData: any = yield select(selectStakeholderData);
  const { updatingData, ...otherSharedItemData } = sharedItemData;

  if (!sharedItemData) {
    yield put(actions.updateStakeholderError({ message: 'Invalid data' }));
    return;
  }

  try {
    const instance = getInstance(UBE_URL);
    const response = yield all(
      otherSharedItemData.accessEntities.map(accessEntity =>
        call(
          instance.patch,
          `/group/${accessEntity.entityId}/stakeholder/${accessEntity.stakeholderId}/update`,
          JSON.stringify(updatingData),
        ),
      ),
    );

    if (!response) {
      throw new Error('an error ocurred updating stakeholder');
    }
    yield put(actions.updateStakeholderSuccess());
    yield put(actions.cleanupGroupOperation());
  } catch (error: any) {
    yield put(
      actions.updateStakeholderError({
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
    toastActions.showErrorToast({
      show: true,
      message:
        error.message || error.message.length > 0
          ? error.message
          : defaultErrorMessage,
    });
  }
}

export function* deleteStakeholderSaga() {
  yield delay(500);
  const instance = getInstance(UBE_URL);
  const stakeholderData: any = yield select(selectStakeholderData);

  if (stakeholderData === undefined) {
    yield put(actions.deleteStakeholderError({ message: 'Invalid data' }));
    return;
  }

  try {
    const { entity, _id } = stakeholderData;
    if (!entity || !_id) {
      throw new Error('Invalid stakeholder id or group id');
    }
    const response = yield call(
      instance.delete,
      `${UBE_URL}/group/${entity}/stakeholder/${_id}`,
    );
    if (!response) {
      throw new Error('An error ocurred while deleting stakeholder');
    }
    yield put(actions.deleteStakeholderSuccess());
    yield put(
      toastActions.showSuccessToast({
        show: true,
        message: 'Deleted successfully',
      }),
    );
  } catch (error: any) {
    yield put(
      actions.deleteStakeholderError({
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
    toastActions.showErrorToast({
      show: true,
      message:
        error.message || error.message.length > 0
          ? error.message
          : defaultErrorMessage,
    });
  }
}

export function* getStakeholderAssetsSaga() {
  yield delay(500);
  try {
    let groupId: any = yield select(selectCurrentGroupId);
    const actorId: any = yield select(selectCurrentStakeholderId);
    if (!groupId) {
      const user = yield select(selectUser);
      groupId =
        user.user?.groups && user.user?.groups.length > 0
          ? user.user.groups[0]
          : undefined;
    }
    if (!groupId || !actorId) {
      throw new Error('CHECK ACTOR_ID OR GROUP_ID');
    }
    const instance = getInstance(UBE_URL);
    const response = yield call(
      instance.get,
      `/group/${groupId}/item?itemType=GROUP_ASSET&groupBy=type&actorId=${actorId}`,
    );
    if (response === void 0 || response === null) {
      throw new Error('an error ocurred getting assets');
    }
    if (!response.data?.payload) {
      throw new Error(defaultErrorMessage);
    }
    yield put(actions.getStakeholderAssetsSuccess(response.data.payload));
  } catch (error: any) {
    yield put(
      actions.getStakeholderAssetsError({
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* getStakeholderLiabilitiesSaga() {
  yield delay(500);
  try {
    let groupId: any = yield select(selectCurrentGroupId);
    const actorId: any = yield select(selectCurrentStakeholderId);
    if (!groupId) {
      const user = yield select(selectUser);
      groupId =
        user.user?.groups && user.user?.groups.length > 0
          ? user.user.groups[0]
          : undefined;
    }
    if (!groupId || !actorId) {
      throw new Error('CHECK ACTOR_ID OR GROUP_ID');
    }
    const instance = getInstance(UBE_URL);
    const response = yield call(
      instance.get,
      `/group/${groupId}/item?itemType=GROUP_LIABILITY&groupBy=type&actorId=${actorId}`,
    );
    if (response === void 0 || response === null) {
      throw new Error('an error ocurred getting liabilities');
    }
    if (!response.data?.payload) {
      throw new Error(defaultErrorMessage);
    }
    yield put(actions.getStakeholderLiabilitiesSuccess(response.data.payload));
  } catch (error: any) {
    yield put(
      actions.getStakeholderLiabilitiesError({
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* getStakeholdersSaga() {
  yield delay(500);
  const stakeholdersGroupId: StakeholdersGroupId = yield select(
    selectStakeholdersGroupId,
  );
  if (!stakeholdersGroupId) {
    yield put(actions.getStakeholdersError({ message: 'Group not found' }));
    return;
  }
  if (stakeholdersGroupId.length === 0) {
    yield put(actions.getStakeholdersSuccess([]));
    return;
  }
  try {
    const instance = getInstance(UBE_URL);
    const response = yield all(
      stakeholdersGroupId.map(stakeholderGroupId =>
        call(
          instance.get,
          `${UBE_URL}/group/${stakeholderGroupId.groupId}/stakeholder/${stakeholderGroupId._id}`,
        ),
      ),
    );
    if (response === void 0 || !response.length) {
      throw new Error('An error ocurred getting stakeholders');
    }
    const stakeholders = response?.map(el => el.data?.payload);
    // get unique stakeholders
    const uniqueStakeholders = stakeholders?.filter(
      (value, index, self) =>
        index === self.findIndex(t => t.email === value.email),
    );
    yield put(actions.getStakeholdersSuccess(uniqueStakeholders));
  } catch (error: any) {
    yield put(actions.getStakeholdersError({ message: error.message }));
  }
}

export function* handleItemChanges() {
  yield put(actions.cleanupGroupOperation());
  yield put(actions.loadGroupData());
}

export function* groupSaga() {
  // Group
  yield takeLatest(actions.getGroupTypes.type, getGroupTypesSaga);
  yield takeLatest(actions.createGroup.type, createGroupSaga);
  yield takeLatest(actions.editGroup.type, editGroupSaga);
  yield takeLatest(actions.deleteGroup.type, deleteGroupSaga);
  yield takeLatest(actions.loadGroupData.type, loadGroupSaga);
  yield takeLatest(actions.cleanupGroupOperation.type, loadGroupSaga);
  yield takeLatest(
    actions.loadGroupDataSuccess.type,
    groupsDataRefreshEffectsSaga,
  );
  yield takeLatest(
    actions.reloadGroupCalculations.type,
    groupsDataRefreshEffectsSaga,
  );
  yield takeLatest(
    actions.setCurrentGroupId.type,
    groupsDataRefreshEffectsSaga,
  );

  // Stakeholders
  yield takeLatest(actions.addStakeholder.type, addStakeholderSaga);
  yield takeLatest(actions.editStakeholder.type, editStakeholderSaga);
  yield takeLatest(actions.updateStakeholder.type, updateStakeholderSaga);
  yield takeLatest(
    actions.updateStatusStakeholder.type,
    updateStatusStakeholderSaga,
  );
  yield takeLatest(actions.deleteStakeholder.type, deleteStakeholderSaga);
  yield takeLatest(actions.getStakeholders.type, getStakeholdersSaga);
  yield takeLatest(actions.getStakeholderAssets.type, getStakeholderAssetsSaga);
  yield takeLatest(
    actions.getStakeholderLiabilities.type,
    getStakeholderLiabilitiesSaga,
  );
  yield takeLatest(actions.addStakeholderSuccess.type, handleItemChanges);
  yield takeLatest(actions.editStakeholderSuccess.type, handleItemChanges);
  yield takeLatest(actions.deleteStakeholderSuccess.type, handleItemChanges);
}
